import React from 'react';
import ReactDOM from 'react-dom';
import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';

import { AppState } from 'Redux/AppState';
import './index.css';
import 'animate.css';
import 'hint.css';

import { AppContextProvider } from 'Context/app.context';
import { CurrentUserContextProvider } from 'Context/current-user.context';

import AuthComponent from 'Components/FunctionalComponents/Auth/auth.component';
import LocalizationComponent from 'Components/FunctionalComponents/Localization/localization.component';
import RenderLoader from 'Components/FullPage/RenderLoader/render-loader.component';
import { AppMode } from 'Common/Enums/metadata.enum';
import RecentContactsReducer from 'Redux/Reducers/recent-contacts.reducer';
import FullPage from 'EntryPoints/full-page.web';
import {
    AppTypeEnum,
    CurrentAppContext,
    MobileViewEnum,
} from 'Context/current-app.context';
import { FilterProvider } from 'Context/current-filter.context';
import { isMobile, isOpenInsideIframe } from 'Common/Utils/CheckDevice';
import Widget from 'EntryPoints/widget';
import ErrorBoundary from 'Components/Reusable/ErrorBoundary/error-boundary';

(window as any).logger = (function () {
    let oldConsoleLog: any = null;
    const pub = {} as any;

    pub.enableLogger = function enableLogger() {
        if (oldConsoleLog == null) return;

        window.console.log = oldConsoleLog;
    };

    pub.disableLogger = function disableLogger() {
        oldConsoleLog = console.log;
        window.console.log = function () {};
    };

    return pub;
})();

// console.log("Disable Logger");
(window as any).logger.disableLogger();

const Ding = require('Assets/Audio/Message-notification.mp3'); // eslint-disable-line @typescript-eslint/no-var-requires

const rootReducer = combineReducers<AppState>({
    recentContacts: RecentContactsReducer,
});

const store = createStore(
    rootReducer,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__() // eslint-disable-line no-underscore-dangle
);

ReactDOM.render(
    <React.StrictMode>
        <audio id="cv-notification-sound">
            <source src={Ding} type="audio/mp3" />
        </audio>
        <ErrorBoundary currentApp={AppTypeEnum.Fullscreen}>
            <AppContextProvider>
                <RenderLoader />
                <CurrentAppContext.Provider
                    value={{
                        appType: AppTypeEnum.Fullscreen,
                        isMobile: isMobile(),
                        mobileView: isMobile()
                            ? isOpenInsideIframe()
                                ? MobileViewEnum.Iframe
                                : window?.location?.origin?.includes(
                                        'converse-widget'
                                    )
                                  ? MobileViewEnum.WebView
                                  : MobileViewEnum.None
                            : MobileViewEnum.None,
                        isOpenInsideIframe: isOpenInsideIframe(),
                    }}
                >
                    <AuthComponent mode={AppMode.FullScreen}>
                        <LocalizationComponent>
                            <CurrentUserContextProvider>
                                <FilterProvider>
                                    <Provider store={store}>
                                        <FullPage />
                                    </Provider>
                                </FilterProvider>
                            </CurrentUserContextProvider>
                        </LocalizationComponent>
                    </AuthComponent>
                </CurrentAppContext.Provider>
            </AppContextProvider>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
