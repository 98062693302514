import log from 'loglevel';
import { ILogger } from '.';

export default function ConsoleLogger(): ILogger {
    return {
        info: (message: string, ...params: any[]) => {
            log.info(message, params);
        },

        warn: (message: string, ...params: any[]) => {
            log.warn(message, params);
        },

        error: (error: Error | string, ...params: any[]) => {
            log.error(error, params);
        },

        debug: (message: string, ...params: any[]) => {
            log.debug(message, params);
        },

        trace: (message: string, ...params: any[]) => {
            log.trace(message, params);
        },
    };
}
